import { React, Component } from 'react'
import '../popup_components.css'
import './complications.css'
import {
  PregnantSelect,
  PregnantMultiSelect,
  PatientHelpModal,
  PregnantSearchMultiSelect
} from 'elements/'
import parseData from './api/post-health-checklist-complication/parse-data'
import { Grid as GridBase, TextField, Typography, Button } from '@mui/material'
import Loading from '../../loading/loading'
import styled from '@emotion/styled'
import {
  postHealthChecklistComplication,
  getHealthChecklistComplicationFields,
  getPrivateDataPregnantId
} from 'api/'
import {
  newComplicationFields,
  newComplicationValidationObject,
  newComplicationSelectValues,
  pregnancyNewComplicationObject
} from './data-structure/new-complications'
import { PropTypes } from 'prop-types'

const TextLabel = styled(Typography)({
  fontWeight: 'bold',
  fontSize: '18px'
})
const TextValue = styled(Typography)({
  fontSize: '16px',
  width: '100%'
})
const Grid = styled(GridBase)({
  justifyContent: 'flex-start'
})

export default class NewComplications extends Component {
  constructor (props) {
    super(props)
    this.state = {
      complication_fields: newComplicationFields,
      PregnancyComplication: pregnancyNewComplicationObject,
      complication_select_values: newComplicationSelectValues,
      validation: newComplicationValidationObject,

      doctor_username: localStorage.getItem('username'),
      week_of_pregnancy_on_date_of_filling: '',
      pregnancy_id: this.props.pregnant_id,
      date_of_creation: new Date().toLocaleDateString('fr-CA'),

      is_save_button_disabled: true,
      save_button_text: 'Сохранить',

      pregnant_loading: true,
      complication_fields_loading: true,
      max_retries: 5,
      drug_list: [],
      is_help_window_open: false
    }
  }

  checkIsValid = (data) => {
    const validationKeys = Object.keys(data)
    const notValid = []
    for (let i = 0; i < validationKeys.length; i++) {
      if (data[validationKeys[i]] !== '') {
        notValid.push(data[validationKeys[i]])
      }
    }
    return [!notValid.length, notValid]
  }

  complicationFields = () => {
    getHealthChecklistComplicationFields()
      .then((res) => {
        if (res.data) {
          console.log(res.data)
          this.setState({ complication_fields: res.data })
        }
      })
  }

  misPregnantId = (pregnantId) => {
    getPrivateDataPregnantId(pregnantId)
      .then((res) => {
        this.setState({ pregnant: res.data, pregnant_loading: false })
      })
      .catch((err) => console.log(err))
  }

  changeSaveButton = (saved) => {
    if (saved) {
      this.setState({
        is_save_button_disabled: true,
        save_button_text: 'Сохранено'
      })
    } else {
      this.setState(
        {
          is_save_button_disabled: true,
          save_button_text: 'Ошибка'
        },
        () => {
          setTimeout(() => {
            this.setState({ is_save_button_disabled: false })
          }, 2000)
        }
      )
    }
  }

  posthealthChecklistComplicationWrap = (data) => {
    const parsedData = parseData(data)
    const [isValid, notValidData] = this.checkIsValid(data.validation)
    if (!isValid) {
      let errorText = ''
      for (let i = 0; i < notValidData.length; i++) {
        errorText += notValidData[i] + '\n'
      }
      this.props.notify('error', errorText)
      return 0
    }
    postHealthChecklistComplication(parsedData)
      .then((res) => {
        this.changeSaveButton(true)
        this.props.notify('success', 'Успешно сохранено')
        this.props.updateCardBlockComponent('complications')
        this.props.setCurrentModal(null, {})
      })
      .catch((err) => {
        if (err.response?.status !== 401) {
          this.changeSaveButton(false)
          this.props.notify('error', `Возникли ошибки ${JSON.stringify(err.response?.data)}`)
        }
      })
  }

  componentDidMount () {
    this.misPregnantId(this.props.pregnant_id)
    this.complicationFields()
  }

  componentDidUpdate () {
    if (
      this.state.PregnancyComplication.complaint.length > 0 ||
      (this.state.PregnancyComplication.danger_sign.length > 0 &&
        this.state.PregnancyComplication.record_type.name)
    ) {
      if (this.state.is_save_button_disabled) {
        this.setState({ is_save_button_disabled: false })
      }
    } else {
      if (!this.state.is_save_button_disabled) {
        this.setState({ is_save_button_disabled: true })
      }
    }
  }

  setMultiSelectVal = (id, val) => {
    this.setState({
      PregnancyComplication: {
        ...this.state.PregnancyComplication,
        [id]: val
      }
    })
    if (
      this.state.PregnancyComplication.danger_sign.length === 0 &&
      this.state.PregnancyComplication.complaint.length === 0 &&
      this.state.PregnancyComplication.taking_drugs === 0
    ) {
      this.setState({
        validation: {
          ...this.state.validation,
          has_drugs_or_complications: newComplicationValidationObject.has_drugs_or_complications
        }
      })
    } else {
      this.setState({
        validation: {
          ...this.state.validation,
          has_drugs_or_complications: ''
        }
      })
    }
  }

  setSelectVal = (id, val) => {
    this.setState(
      {
        PregnancyComplication: {
          ...this.state.PregnancyComplication,
          [id]: val
        }
      },
      () => {
        const validationState = !val ? newComplicationValidationObject[id] : ''
        if (this.state.validation[id] !== validationState) {
          this.setState({
            validation: {
              ...this.state.validation,
              [id]: validationState
            }
          })
        }
      }
    )
  }

  setTextVal = (id, val) => {
    if (val) {
      this.setState({
        validation: { ...this.state.validation, [id]: '' }
      })
      if (this.state.PregnancyComplication.anamnesis !== val) {
        this.setState({ PregnancyComplication: { ...this.state.PregnancyComplication, [id]: val } })
      }
    } else {
      this.setState({
        validation: { ...this.state.validation, [id]: newComplicationValidationObject[id] }
      })
      if (this.state.PregnancyComplication.anamnesis !== val) {
        this.setState({ PregnancyComplication: { ...this.state.PregnancyComplication, [id]: val } })
      }
    }
  }

  render () {
    return (
      <>
        {this.state.pregnant_loading && this.state.complication_fields_loading
          ? (
            <Loading></Loading>
            )
          : (
          <div>
            <div style={{ overflowY: 'auto', height: '71vh', padding: '1%' }}>
              <div className='popup_card-block'>
                <Grid container gap={2}>
                  <Grid xs={3} lg={3} md={3} item>
                    <TextLabel>Жалобы</TextLabel>
                    <PregnantMultiSelect
                      id='complaint'
                      error={Boolean(this.state.validation.complaint)}
                      value={this.state.PregnancyComplication.complaint}
                      onChange={this.setMultiSelectVal}
                      choices={this.state.complication_fields.Complaint}
                    />
                  </Grid>
                  <Grid xs={4} lg={4} md={4} item>
                    <TextLabel>Тип обращения</TextLabel>
                    <PregnantMultiSelect
                      error={Boolean(this.state.validation.danger_sign)}
                      id='danger_sign'
                      value={this.state.PregnancyComplication.danger_sign}
                      onChange={this.setMultiSelectVal}
                      choices={this.state.complication_fields.DangerSign}
                    />
                  </Grid>
                  <Grid xs={4} lg={4} md={4} item>
                    <TextLabel>Подтип обращения</TextLabel>
                    <PregnantSearchMultiSelect
                      id='taking_drugs'
                      error={Boolean(this.state.validation.taking_drugs)}
                      onChange={this.setMultiSelectVal}
                      options={this.state.complication_fields.Drugs || []}
                      choices={this.state.PregnancyComplication.taking_drugs}
                    />
                  </Grid>
                </Grid>
              </div>
              <div className='popup_card-block'>
                <Grid spacing={2} container>
                  <Grid xs={3} lg={3} md={3} item>
                    <TextLabel>Неделя беременности на дату заполнения</TextLabel>
                    <TextValue>{this.props.current_week_of_pregnancy}</TextValue>
                  </Grid>
                  <Grid xs={3} lg={3} md={3} item>
                    <TextLabel>Триместр</TextLabel>
                    <PregnantSelect
                      error={Boolean(this.state.validation.diagnosis)}
                      id='diagnosis'
                      onChange={this.setSelectVal}
                      value={this.state.PregnancyComplication.diagnosis}
                      choices={this.state.complication_fields.Diagnosis}
                    />
                  </Grid>
                  <Grid xs={3} lg={3} md={3} item>
                    <TextLabel>Статус обращения</TextLabel>
                    <PregnantSelect
                      error={Boolean(this.state.validation.record_type)}
                      onChange={this.setSelectVal}
                      id='record_type'
                      value={this.state.PregnancyComplication.record_type}
                      choices={this.state.complication_fields.RecordType}
                    />
                  </Grid>
                  <Grid xs={3} lg={3} md={3} item>
                    <TextLabel>Замечания и пожелания</TextLabel>
                    <PregnantSelect
                      error={Boolean(this.state.validation.diagnosis_status)}
                      onChange={this.setSelectVal}
                      id='diagnosis_status'
                      value={this.state.PregnancyComplication.diagnosis_status.name}
                      choices={this.state.complication_fields.DiagnosisStatus}
                    />
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid xs={12} lg={12} md={12} item>
                    <TextLabel style={{ textAlign: 'start' }}>Комментарий</TextLabel>
                    <TextField
                      id='anamnesis'
                      error={Boolean(this.state.validation.anamnesis)}
                      helperText={this.state.validation.anamnesis}
                      onChange={(event) => this.setTextVal(event.target.id, event.target.value)}
                      value={this.state.PregnancyComplication.anamnesis}
                      multiline='true'
                      rows={5}
                      fullWidth
                    ></TextField>
                  </Grid>
                </Grid>
                <Grid mt={4} container>
                  <Grid item container>
                    <Grid item>
                      <TextLabel>Неделя беременности на дату заполнения: </TextLabel>
                    </Grid>
                    <Grid item>
                      <TextValue>{this.props.current_week_of_pregnancy}</TextValue>
                    </Grid>
                  </Grid>
                  <Grid item container>
                    <Grid item>
                      <TextLabel>Дата записи: </TextLabel>
                    </Grid>
                    <Grid item>
                      <TextValue>{this.state.PregnancyComplication.date_of_creation}</TextValue>
                    </Grid>
                  </Grid>
                  <Grid item container>
                    <Grid item>
                      <TextLabel>Врач ТМЦ: </TextLabel>
                    </Grid>
                    <Grid item>
                      <TextValue>{localStorage.getItem('full_name')}</TextValue>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            </div>
            <Grid mt={3} container style={{ justifyContent: 'flex-end' }}>
              <Grid xs={4} md={4} lg={4} textAlign='end' item>
                <Button
                  onClick={() => this.setState({ is_help_window_open: true })}
                  style={{
                    fontSize: '16px',
                    fontWeight: 'bold',
                    textTransform: 'none'
                  }}
                  variant='contained'
                  color='primary'
                >
                  Сформировать справку
                </Button>
              </Grid>
              <Grid xs={4} md={4} lg={4} flexDirection='column' item container>
                <Grid item>
                  <Button
                    disabled={this.state.is_save_button_disabled}
                    onClick={() => {
                      this.posthealthChecklistComplicationWrap(this.state)
                    }}
                    style={{
                      fontSize: '16px',
                      fontWeight: 'bold',
                      textTransform: 'none',
                      width: '60%'
                    }}
                    variant='contained'
                    color='primary'
                  >
                    {this.state.save_button_text}
                  </Button>
                </Grid>

                {this.state.complication_select_values.complaint.length > 0 ||
                (this.state.complication_select_values.danger_sign.length > 0 &&
                  this.state.PregnancyComplication.record_type.name)
                  ? (
                      ''
                    )
                  : (
                  <Grid item>
                    <Typography
                      className='MuiFormHelperText-root
                                                MuiFormHelperText-sizeSmall
                                                MuiFormHelperText-contained
                                                MuiFormHelperText-filled'
                      style={{ fontSize: '0.75rem' }}
                    >
                      Для создания записи добавьте жалобы или тревожные признаки
                    </Typography>
                  </Grid>
                    )}
              </Grid>
            </Grid>
          </div>
            )}
        <PatientHelpModal
          open={this.state.is_help_window_open}
          onClose={() => this.setState({ is_help_window_open: false })}
          data={{
            ...this.state.pregnant,
            week: this.props.current_week_of_pregnancy,
            PregnancyComplication: this.state.PregnancyComplication
          }}
        />
      </>
    )
  }
}

NewComplications.propTypes = {
  pregnant_id: PropTypes.number,
  current_week_of_pregnancy: PropTypes.number,
  notify: PropTypes.object,
  updateCardBlockComponent: PropTypes.func,
  setCurrentModal: PropTypes.func
}
