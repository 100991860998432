import axios from 'axios'

export default function downloadReportRequest (genetatingReportId) {
  const request = axios.post(
    'reports/download_report',
    {
        'report_id': genetatingReportId,
    },
    {
      responseType: 'blob'
    }
  )
  return request
}

// import axios from 'axios'

// export default function generateReport (serviceType, reportType) {
//     const request = axios({
//         method: 'post',
//         url: 'reports/create_report',
//         data: {
//             'service_type': serviceType,
//             'report_name': reportType
//         }
//     })
//     return request
// }
