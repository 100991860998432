import { React } from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import getReportTypes from '../api/get-report-types'
import generateReport from '../api/generate-report'
import { useState } from 'react'
import InputGroup from 'react-bootstrap/InputGroup'

export default function GenerateReportMenu({
  setServiceType,
  setAvailableReportTypes,
  setReportType,
  makeGetReportGeneratingStatuses,
  serviceType,
  reportType,
  availableServicesTypes,
  availableReportTypes,
}) {
  const [validated, setValidated] = useState(false)
  let [invalidText, setinvalidText] = useState('')
  let [isFormInvalid, setisFormInvalid] = useState(false)

  function handleSelectServiceType(event) {
    setServiceType(event.target.value)
    getReportTypes(event.target.value).then((res) => {
      setAvailableReportTypes(res.data)
      setReportType(res.data[0].name)
    })
  }

  function handleSelectReportType(event) {
    setReportType(event.target.value)
  }

  function startGenerateReport(event) {
    event.preventDefault()
    generateReport(serviceType, reportType)
      .then((res) => {
        makeGetReportGeneratingStatuses()
      })
      .catch((err) => {
        setValidated(false)
        setisFormInvalid(true)
        if (err.response.status === 403) {
          setinvalidText(err.response.data.detail)
        } else {
          setinvalidText(err.response.data.non_field_errors[0])
        }
      })
  }

  return (
    <>
      <Container className='mt-5 mb-5'>
        <Row>
          <Col>
            <Form.Label>Формирование отчётности</Form.Label>
            <Form noValidate validated={validated} onSubmit={startGenerateReport}>
              <Form.Group className='mb-5'>
                <Form.Label>Сервис</Form.Label>
                <Form.Select onChange={(e) => handleSelectServiceType(e)}>
                  {availableServicesTypes.map((availableServiceType) => (
                    <option key={availableServiceType.name} value={availableServiceType.name}>
                      {availableServiceType.name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>

              <Form.Group className='mb-3'>
                <Form.Label>Вид отчёта</Form.Label>
                <InputGroup hasValidation>
                  <Form.Control
                    required
                    as='select'
                    type='select'
                    name='payment_method'
                    onChange={handleSelectReportType}
                    isInvalid={isFormInvalid}
                  >
                    {availableReportTypes.map((availableReportType) => (
                      <option key={availableReportType.name} value={availableReportType.name}>
                        {availableReportType.name}
                      </option>
                    ))}
                  </Form.Control>
                  <Form.Control.Feedback type='invalid'>{invalidText}</Form.Control.Feedback>
                </InputGroup>
              </Form.Group>

              <Button type='submit' variant='primary'>
                Сформировать отчёт
              </Button>
            </Form>
          </Col>
        </Row>
      </Container>
    </>
  )
}
