import { React } from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import ProgressBar from 'react-bootstrap/ProgressBar'
import Button from 'react-bootstrap/Button'
import downloadReportRequest from '../api/download-report'

export default function GeneratedReportsStatuses({ generatingReports }) {
  function downloadReport(genetatingReportId) {
    downloadReportRequest(genetatingReportId)
      .then((res) => {
        const blob = new Blob([res.data], { type: res.headers['content-type'] })
        const url = URL.createObjectURL(blob)
        const anchor = document.createElement('a')
        anchor.href = url
        anchor.download = `report_${new Date().getTime()}.xlsx`
        document.body.append(anchor)
        anchor.style = 'dispaly: none'
        anchor.click()
        anchor.remove()
        URL.revokeObjectURL(url)
      })
      .catch((err) => {
        console.log(err)
      })
      .finally(() => {})
  }

  return (
    <>
      <Container className='mt-5'>
        <Row>
          <Col className='mb-4'>Формирующиеся отчёты</Col>
        </Row>
        {generatingReports.map((genetatingReport) => (
          <>
            <Row>
              <Col className='mb-3'>{genetatingReport.service_type + ' ' + genetatingReport.report_name}</Col>
            </Row>
            <Row>
              <Col className='mb-3'>
                <ProgressBar now={genetatingReport.readiness} label={`${genetatingReport.readiness}%`} />
              </Col>
            </Row>
            <Row>
              <Col>
                {genetatingReport.readiness === 100 && (
                  <Button
                    className='col-md-3 mb-5'
                    variant='success'
                    onClick={() => downloadReport(genetatingReport.id)}
                  >
                    Скачать отчёт
                  </Button>
                )}
              </Col>
            </Row>
          </>
        ))}
      </Container>
    </>
  )
}
