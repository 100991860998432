import axios from 'axios'

export default function generateReport (serviceType, reportType) {
    const request = axios({
        method: 'post',
        url: 'reports/create_report',
        data: {
            'service_type': serviceType,
            'report_name': reportType
        }
    })
    return request
}
