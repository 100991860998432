import { React, useEffect, useState } from 'react'
import PregnantSearchField from './elements/pregnant-text-field'
import PregnantResultsTableDesktop from './elements/pregnant-results-table-desktop'
import parseData from './utils/parse-data'
import parseDataForCall from './utils/parse-data-for-call'
import { Grid, Button } from '@mui/material'
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined'
import columns from './utils/columns'
import NewKid from './components/new-kid'
import { gaveBirthSearch, postCallHistoryCreateCallHistory } from 'api/'
import Kid from './components/kid'
import Pregnant from './components/pregnant'
import brightpatternConfig from 'brightpattern-config'
import Widget from 'views/pregnant_card/components/widget/widget'
import Notification from 'views/pregnant_card/notification/notification'
import PregnantChosePhone from './elements/pregnant-chose-phone'
import { PropTypes } from 'prop-types'

const cols = columns
const modalBlockStyles = {
  borderRadius: '20px',
  border: '1px solid #ced4da',
  marginLeft: '1.4%',
  marginRight: '1.4%',
  padding: '2%',
}

const ChildButton = (props) => {
  const [defaultText] = useState('Заполнить информацию о ребенке')
  return (
    <Button
      variant='contained'
      style={{
        textTransform: 'none',
        backgroundColor: props.color,
        fontWeight: 500,
        fontSize: '1.3em',
        width: '85%',
      }}
      onClick={() => props.onClick(props.child_id)}
    >
      {defaultText} <br /> {props.child_fio}
    </Button>
  )
}

ChildButton.propTypes = {
  child_id: PropTypes.number,
  color: PropTypes.string,
  child_fio: PropTypes.string,
  onClick: PropTypes.func,
}

const ButtonBirthList = (props) => {
  return (
    <Button
      variant='contained'
      onClick={() => props.onClick(props.id)}
      fullWidth
      style={{
        textTransform: 'none',
        backgroundColor: props.color,
        fontWeight: 500,
        fontSize: '1.3em',
        width: '85%',
      }}
    >
      {props.text}
    </Button>
  )
}

ButtonBirthList.propTypes = {
  id: PropTypes.number,
  color: PropTypes.string,
  text: PropTypes.string,
  onClick: PropTypes.func,
}

export default function GaveBirthList({ iin, closePregnantInfoModal }) {
  const [query, setQuery] = useState('')
  const [rows, setRows] = useState([])
  const [searchTimeout, setSearchTimeout] = useState(null)
  const [columns] = useState(cols)
  const [childId] = useState(undefined)
  const [pageLoaded, setPageLoaded] = useState('')
  const [kidModalOpen, setKidModalOpen] = useState({ id: undefined, is_open: false })
  const [newKidModalOpen, setNewKidModalOpen] = useState({ is_open: false })
  const [pregnantModalOpen, setPregnantModalOpen] = useState(false)
  const [pregnancyId, setPregnancyId] = useState('')
  const [pregnancyIdForCall, setPregnancyIdForCallGlobal] = useState('qwe')
  const [callButtonDisabled, setCallButtonState] = useState(true)
  const [chosePhone, setChosePhone] = useState('')
  const [notification, setNotification] = useState({ open: false, type: false, message: '' })
  const [bpInited, setBpInited] = useState(false)
  const [key, setKey] = useState(1)

  const initBrightPatternInterface = () => {
    const url = process.env.NODE_ENV === 'development' ? brightpatternConfig.prod_url : brightpatternConfig.url
    window.bpspat.api.init(url)
  }
  const initBrightPatternCall = (phone) => {
    if (!phone) {
      notify('error', 'Сначала нужно выбрать номер!')
      return 0
    }
    const service =
      process.env.NODE_ENV === 'development' ? brightpatternConfig.prod_service : brightpatternConfig.service
    window.bpspat.api.selectService(service)
    window.bpspat.api.dialNumber(phone.replace('+7', '7'))
  }
  const setCallData = (data) => {
    console.log(33333333333333)
    console.log(data)
    console.log(localStorage.getItem('pregnancy_id'))
    localStorage.setItem('global_id', data.data.global_id)
    data.data.date_of_call = new Date().toLocaleDateString('en-CA') + 'T' + new Date().toLocaleTimeString('it-IT')
    data.data.doctor_username = localStorage.getItem('username')
    data.data.pregnancy_id = localStorage.getItem('pregnancy_id')
    data.data.phone = chosePhone
    createCallHistory(data.data)
  }
  const createCallHistory = (data) => {
    postCallHistoryCreateCallHistory(parseDataForCall(data))
      .then((res) => {
        if (res.data) {
          notify('success', 'Запись о звонке успешно добавлена')
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
  const BrightPatternMessageEventHandler = (event) => {
    const authenticatedMessages = [
      'STATE_CHANGED',
      'WELCOME_DATA',
      'DEFAULT_COUNTRY',
      'CREATE_CHAT',
      'CHAT_HISTORY',
      'GET_DIRECTORY',
      '_BLIND_TRANSFER',
      '_CONSULT_TRANSFER',
      'AUTH_ACCEPT',
    ]
    const isNotAuthenticatedMessages = ['SHOW_LOGIN_FORM', 'LOGIN_CHANGED']
    const isBusyMessages = [
      '_O_CALL_DIALING',
      'INTERACTION_RENDERED',
      'HIDE_RESCHEDULE_FORM',
      'HIDE_CONFERENCE_PARTY_LIST',
      'CONVERSATION_LIST',
    ]
    const url = process.env.NODE_ENV === 'development' ? brightpatternConfig.prod_url : brightpatternConfig.url
    if (event.origin === url) {
      let command = ''
      try {
        command = JSON.parse(event.data).command
      } catch {
        return 0
      }
      if (isNotAuthenticatedMessages.includes(command)) {
        setCallButtonState(true)
      }
      if (authenticatedMessages.includes(command)) {
        setCallButtonState(false)
      }
      if (isBusyMessages.includes(command)) {
        setCallButtonState(true)
      }
      if (command === 'INTERACTION_COMPLETED') {
        const data = JSON.parse(event.data)
        if (localStorage.getItem('global_id') !== data.global_id) {
          localStorage.setItem('global_id', data.global_id)
          setCallData(data)
        }
        setCallButtonState(false)
      }
      if (command === 'STATE_CHANGED') {
        const data = JSON.parse(event.data)
        if (data.status === 'ready') {
          setCallButtonState(false)
        }
      }
    }
  }
  const handleSetChosePhone = (val) => {
    console.log(val)
    setChosePhone(val)
  }
  const handleKidModalCalled = (childId) => {
    setKidModalOpen({ id: childId, is_open: true })
  }
  const handleNewKidModalCalled = () => {
    return setNewKidModalOpen({ is_open: true })
  }
  const handleKidModalClose = () => {
    setKidModalOpen({ is_open: false, id: undefined })
  }
  const handleNewKidModalClose = () => {
    setNewKidModalOpen({ is_open: false })
  }
  const handlePregnantModalClose = () => {
    setPregnantModalOpen(false)
  }
  const handlePregnantModalCalled = (id) => {
    setPregnantModalOpen(true)
  }
  const setIframeActive = () => {
    setPageLoaded(!pageLoaded)
  }
  const getData = (search) => {
    clearTimeout(searchTimeout)

    setSearchTimeout(
      setTimeout(() => {
        gaveBirthSearch(search)
          .then((res) => {
            if (res.data) {
              const parsedData = parseData(res.data)
              const rows = []
              rows.push(parsedData)
              setRows(rows)
              setKey(key + 1)
            }
          })
          .catch((err) => {
            setRows([])
            console.log(err)
          })
      }, 1000)
    )
  }
  useEffect(() => {
    setQuery(iin)
    getData(query)
  }, [query])

  useEffect(() => {
    console.log(11111111111111111111111111111111111111111)
    console.log(rows[0])
    console.log(rows[0]?.pregnancy_id)
    localStorage.setItem('pregnancy_id', rows[0]?.pregnancy_id)
    setPregnancyId(rows[0]?.pregnancy_id)
  }, [rows])

  useEffect(() => {
    if (!bpInited) {
      const url = process.env.NODE_ENV === 'development' ? brightpatternConfig.prod_url : brightpatternConfig.url
      window.bpspat.api.init(url)
      setBpInited(true)
      window.addEventListener('message', BrightPatternMessageEventHandler)
    }
    return () => {
      window.removeEventListener('message', BrightPatternMessageEventHandler)
    }
  }, [])
  const notify = (type, message, close) => {
    if (close) {
      setNotification({ open: false, message: notification.message, type: notification.type })
    } else {
      setNotification({
        type,
        message,
        open: true,
      })
    }
  }
  return (
    <Grid container gap={2} mt={2}>
      <Grid textAlign='center' item lg={12} xs={12} md={12}>
        <PregnantResultsTableDesktop rows={rows} columns={columns} />
      </Grid>
      {rows.length > 0 && (
        <Grid item container lg={12} xs={12} md={12} justifyContent='center' style={modalBlockStyles}>
          <Grid lg={4} xs={4} md={4} item container>
            <Grid item xs={12} md={12} lg={12} textAlign='start'>
              <ButtonBirthList
                key={3000}
                onClick={handlePregnantModalCalled}
                color='orange'
                text='Заполнить информацию о женщине'
              />
            </Grid>
            {rows[0]?.childs?.map((child, index) => {
              return (
                <Grid key={index} xs={12} md={12} lg={12} item textAlign='start' mt={1}>
                  <ChildButton
                    key={4000 + child?.child_id}
                    variant='contained'
                    color='#DC143C'
                    child_id={child?.child_id}
                    onClick={handleKidModalCalled}
                    child_fio={child?.full_name}
                  />
                </Grid>
              )
            })}
            <Grid item xs={12} md={12} lg={12} mt={1} textAlign='start'>
              <ButtonBirthList
                color='green'
                key={999}
                text='Добавить информацию о ребенке'
                onClick={handleNewKidModalCalled}
              />
            </Grid>
          </Grid>
          <Grid lg={3} xs={3} md={3} item flexDirection='column' container spacing={1}>
            <Grid item>
              <PregnantChosePhone choices={rows[0].pregnant.phones} onChange={handleSetChosePhone} />
            </Grid>
            <Grid item>
              <Button
                onClick={() => initBrightPatternCall(chosePhone)}
                variant='contained'
                fullWidth
                color='primary'
                disabled={callButtonDisabled}
                style={{ fontSize: '1.3em', fontWeight: '600', textTransform: 'none' }}
              >
                Позвонить
              </Button>
            </Grid>
          </Grid>
        </Grid>
      )}
      <Widget
        setIframeActive={setIframeActive}
        loaded={pageLoaded}
        initBrightPatternInterface={initBrightPatternInterface}
      />
      <NewKid
        key={1000 + key}
        pregnancy_id={pregnancyId}
        getData={getData}
        date_of_childbirth={rows[0]?.info_about_pregnant_at_current_pregnancy.date_of_childbirth}
        query={query}
        handleNewKidModalClose={handleNewKidModalClose}
        toggled={newKidModalOpen.is_open}
        notify={notify}
      />
      <Kid
        setKey={setKey}
        setKeyProp={parseInt(childId)}
        key={parseInt(childId) + 2000}
        child_id={kidModalOpen.id}
        handleKidModalClose={handleKidModalClose}
        pregnancy_id={pregnancyId}
        toggled={kidModalOpen.is_open}
        notify={notify}
      />
      <Pregnant
        key={key + 5000}
        setKey={setKey}
        setKeyProp={key}
        pregnancy_id={pregnancyId}
        setPregnancyIdGlobal={setPregnancyIdForCallGlobal}
        toggled={pregnantModalOpen}
        handlePregnantModalClose={handlePregnantModalClose}
        notify={notify}
      />
      <Notification message={notification.message} open={notification.open} type={notification.type} onClose={notify} />
    </Grid>
  )
}
