export default function parseDataForCall (data) {
    console.log(222222222222222222)
    console.log(data)
    const emptyData = {}
    Object.assign(emptyData, { date_of_call: data.date_of_call.replace(', ', 'T').replace(' p.m.', '') })
    Object.assign(emptyData, { status_of_call: { name: data.disposition } })
    Object.assign(emptyData, { type_of_call: { name: 'Аудиозвонок' } })
    Object.assign(emptyData, { item_id: data.item_id })
    Object.assign(emptyData, { global_id: data.global_id })
    Object.assign(emptyData, { phone: '+7' + data.phone_number.slice(1) })
    Object.assign(emptyData, { doctor_username: data.doctor_username })
    Object.assign(emptyData, { pregnancy_id: data.pregnancy_id })
    Object.assign(emptyData, { recording_url: data.recording_url })
    return emptyData
  }