import { React, useState, useEffect } from 'react'
import getServices from './api/get-services'
import getReportTypes from './api/get-report-types'
import getReportGeneratingStatuses from './api/get-report-generating-statuses'
import GenerateReportMenu from './components/generate-report-menu'
import GeneratedReportsStatuses from './components/generating-reports-statuses'

export default function ReportsView(props) {
  let [loading, setloading] = useState(true)

  let [serviceType, setServiceType] = useState()
  let [reportType, setReportType] = useState()
  let [availableServicesTypes, setAvailableServicesTypes] = useState()
  let [availableReportTypes, setAvailableReportTypes] = useState()

  let [generatingReports, setGeneratingReports] = useState()

  function makeGetReportGeneratingStatuses() {
    getReportGeneratingStatuses()
      .then((res) => {
        setGeneratingReports(res.data)
      })
      .catch((err) => {})
  }

  function getAvailableReportServices() {
    setloading(true)

    getServices()
      .then((res) => {
        setAvailableServicesTypes(res.data)
        setServiceType(res.data[0].name)

        getReportTypes(res.data[0].name)
          .then((res) => {
            setAvailableReportTypes(res.data)
            setReportType(res.data[0].name)

            setloading(false)
          })
          .catch((err) => {})
      })
      .catch((err) => {})
  }

  useEffect(() => {
    getAvailableReportServices()
    makeGetReportGeneratingStatuses()
    let requestTimer = setInterval(makeGetReportGeneratingStatuses, 1000)

    return () => {
      clearTimeout(requestTimer)
    }
  }, [])

  return (
    <>
      {loading && <p>Loading...</p>}
      {!loading && (
        <GenerateReportMenu
          setServiceType={setServiceType}
          setAvailableReportTypes={setAvailableReportTypes}
          setReportType={setReportType}
          makeGetReportGeneratingStatuses={makeGetReportGeneratingStatuses}
          serviceType={serviceType}
          reportType={reportType}
          availableServicesTypes={availableServicesTypes}
          availableReportTypes={availableReportTypes}
        />
      )}
      {loading && <p>Loading...</p>}
      {!loading && <GeneratedReportsStatuses generatingReports={generatingReports} />}
    </>
  )
}
